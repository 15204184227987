@import "/vercel/path0/frontend/scss/common/variables";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/functions";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/variables";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/mixins";
.wrapper {
  position: relative;
  padding: 1.75rem 0;
  background: theme-color();

  @media (min-width: breakpoint-min(md)) {
    padding: 3rem 0;
  }
}

.title {
  font-weight: 400;
  color: #fff;
  text-align: center;
  letter-spacing: 0.047rem;

  @include font-size(2.25rem);
}

.scrollButton {
  position: absolute;
  right: 15px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  background-color: #fff;
  border: 0;
  border: 1px solid #fff;
  border-radius: 50%;
  box-shadow: 0 0.625rem 2.5rem rgb(0 0 0 / 4%);
  transition: all 0.3s ease;
  transform: translateY(50%);

  @media (min-width: breakpoint-min(md)) {
    right: auto;
    left: 50%;
    transform: translate(-50%, 50%);
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #ebf0f2;
    border-color: #29667f;
    box-shadow: 0 0.625rem 2.5rem rgb(0 0 0 / 20%);
  }

  svg {
    width: 1rem;
    height: 1rem;
    color: theme-color(secondary);
    animation: bounce 2s infinite;
  }
}

@keyframes bounce {
  0%,
  66% {
    transform: translateY(0);
  }

  33% {
    transform: translateY(3px);
  }
}
