@import "/vercel/path0/frontend/scss/common/variables";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/functions";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/variables";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/mixins";
.wrapper {
  border-top: 6px solid theme-color();
}

.top {
  padding: 0 0 1.56rem;
  font-size: 0.875rem;
  line-height: 1.63;

  @media (min-width: breakpoint-min(lg)) {
    padding: 0 0 5.1875rem;
    font-size: 1rem;
  }

  .links {
    a {
      color: currentColor;
    }
  }

  .hours {
    color: #5c6670;
  }
}

.socials {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 24rem;
  padding: 3.125rem 2rem;
  margin: 0 auto;
  text-align: center;

  @media (min-width: breakpoint-min(lg)) {
    padding: 5.1875rem 2rem;
  }

  a {
    display: block;
    margin: 0;
    color: #5c6670;

    @media (min-width: breakpoint-min(lg)) {
      margin: 0;
    }

    &:hover {
      color: theme-color();
      text-decoration: none;
    }

    svg {
      width: 1.375rem;
      height: 1.375rem;
    }
  }
}

.title {
  margin-bottom: 1rem;
  font-size: 1.375rem;
  font-weight: 600;

  @media (min-width: breakpoint-min(lg)) {
    margin-bottom: 2.3rem;
    font-size: 1.5rem;
  }
}

.contact {
  a {
    display: flex;
    align-items: center;
  }

  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;
    color: currentColor;
  }
}

.bottom {
  padding: 1.25rem 0;
  font-size: 0.75rem;
  color: #5c6670;
  background-color: #fafafa;
  border-top: 1px solid #eee;

  @media (min-width: breakpoint-min(lg)) {
    padding: 2.25rem 0;
    font-size: 0.875rem;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    a {
      color: currentColor;
    }
  }
}

.separator {
  position: relative;
  margin-top: 1.75rem;
  border-top: 1px solid #e4e4e4;

  @media (min-width: breakpoint-min(lg)) {
    margin-top: 0;
    border-top: none;

    &::after {
      position: absolute;
      top: 0;
      left: 50%;
      width: 1px;
      height: 100%;
      content: "";
      background-color: #e4e4e4;
    }
  }
}
