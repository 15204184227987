@import "/vercel/path0/frontend/scss/common/variables";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/functions";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/variables";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/mixins";
.box {
  max-width: 970px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 16px 40px rgb(0 0 0 / 6%);
}

.imageWrapper {
  background-color: #fdfcfc;
  border-bottom: 1px solid #f4f4f4;

  img {
    max-height: 10rem;
  }

  [role="button"] {
    cursor: default;
  }

  @media (min-width: breakpoint-min(md)) {
    border-right: 1px solid #f4f4f4;
    border-bottom: 0;

    img {
      max-height: 100%;
    }
  }
}

.contentWrapper {
  padding: 1.8rem 1rem;

  @media (min-width: breakpoint-min(md)) {
    padding: 3rem 4.375rem;
  }
}

.title {
  font-size: 1.5rem;
  color: #1b1b1b;
  letter-spacing: 0.031rem;
}

.subtitle {
  margin-top: 1rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.71;
  color: theme-color();
  letter-spacing: 0.019rem;
}
