@import "/vercel/path0/frontend/scss/common/variables";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/functions";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/variables";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/mixins";
.navbar {
  box-shadow: 0 1px 0 #f4f4f4;

  @media (min-width: breakpoint-min(lg)) {
    padding: 2rem 0;
  }

  @media (min-width: breakpoint-min(xl)) {
    padding: 3.125rem 0;
  }

  :global {
    @media (max-width: breakpoint-max(md)) {
      .container {
        max-width: 100%;
      }
    }

    .navbar-collapse {
      @media (max-width: breakpoint-max(lg)) {
        padding: 0 15px;
        margin-top: 1.5rem;
        margin-right: -15px;
        margin-left: -15px;

        &.collapsing,
        &.show {
          background: #fafafa;
          border-top: 1px solid #e4e4e4;
        }
      }
    }

    .nav-item {
      @media (min-width: breakpoint-min(xl)) {
        padding: 0 0.75rem;
      }
    }

    .nav-link {
      padding: 1rem;
      font-size: 0.875rem;
      font-weight: 700;
      text-align: center;
      border-bottom: 1px solid #e4e4e4;
      transition: all 0.3s ease-in;

      @media (min-width: breakpoint-min(xl)) {
        padding: $nav-link-padding-y $nav-link-padding-x;
        font-size: 1rem;
        text-align: left;
        border-bottom: 2px solid transparent;
      }

      &.active {
        border-color: currentColor;
      }
    }

    .navbar-brand {
      svg {
        max-width: 260px;
      }

      @media (width <= 800px) {
        max-width: 100%;

        svg {
          width: 200px;
        }
      }

      @media (width <= 500px) {
        max-width: 100%;

        svg {
          width: 130px;
          height: 52px;
        }
      }
    }

    .navbar-toggler {
      font-size: 0;

      &:focus {
        outline: none;
      }
    }

    .btn {
      white-space: nowrap;

      @media (width <= 400px) {
        /* stylelint-disable scss/operator-no-unspaced */
        padding: $btn-padding-y/1.5 $btn-padding-x/1.5;
      }
    }
  }
}

.searchForm {
  position: relative;

  :global .form-control {
    width: 100%;
    height: 2.8rem;
    padding: 0.8125rem 0;
    font-weight: 400;
    line-height: 1.71;
    letter-spacing: 0.019rem;
    background: transparent;
    border: 0;
    border-bottom: 1px solid $input-border-color;
    border-radius: 0;

    @media (min-width: breakpoint-min(md)) {
      font-size: 0.875rem;
    }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }

    &:focus {
      border-color: theme-color();
      box-shadow: none;
    }
  }

  :global .btn {
    position: absolute;
    top: 50%;
    right: 0;
    padding: 0;
    margin: 0 0 0 0.2rem;
    font-size: 0;
    transform: translateY(-50%);

    @media (min-width: breakpoint-min(xl)) {
      background: #fff;
    }

    svg {
      width: 0.875rem;
      height: 0.875rem;
      margin: 0;
      color: theme-color();
    }
  }

  .searchAutocomplete {
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 2;
    width: 317px;
    max-height: 340px;
    padding: 0.25rem 1rem;
    margin: 0;
    overflow-y: auto;
    list-style: none;
    background: #fff;
    border: 1px solid #e8e8e8;

    a {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.75rem 0;
      margin-top: -2px;
      font-size: 0.82rem;
      font-weight: 700;
      color: currentColor;
      border: 1px solid transparent;
      transition: all 0.3s ease;

      span {
        padding-right: 0.5rem;
      }

      & > * {
        position: relative;
        z-index: 2;
      }

      &::after {
        position: absolute;
        top: 0;
        left: -12px;
        z-index: 1;
        width: calc(100% + 24px);
        height: 100%;
        content: "";
        background: #fafafa;
        border: 1px solid #e8e8e8;
        border-radius: 4px;
        opacity: 0%;
        transition: all 0.3s ease;
      }

      &:hover,
      &.selected {
        text-decoration: none;
        border-color: transparent !important;

        &::after {
          opacity: 100%;
        }
      }
    }

    li:not(:last-child) a {
      border-bottom: 1px solid #e4e4e4;
    }
  }
}

.cartIcon {
  position: relative;
  display: block;
  font-size: 0;

  svg {
    width: 1.25rem;
    height: 1.188rem;
  }

  &.active::after {
    position: absolute;
    top: 0;
    right: -0.25rem;
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    content: "";
    background: theme-color(danger);
    border-radius: 50%;
  }
}
