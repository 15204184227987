@import "/vercel/path0/frontend/scss/common/variables";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/functions";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/variables";@import "/vercel/path0/frontend/node_modules/bootstrap/scss/mixins";
.wrap {
  font-size: 0.875rem;
  background-color: #f4f4f4;
  border-bottom: 1px solid #e4e4e4;

  :global {
    .breadcrumb-item a {
      text-decoration: underline;
    }
  }
}
